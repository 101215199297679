import React from 'react'
import Layout from '../components/layout'
import HeaderSubsite from '../components/headersubsite'
import ServiceBlog from '../components/serviceblog'
import Footer from '../components/footer'
import SubFooter from '../components/subfooter'

import BackgroundImageTwo from '../images/shutterstock_1090445729.jpg'
import Placeholder from '../images/mario2.jpg'

const LeistungenPage = () => (
  <Layout>
    <HeaderSubsite
      headline="Leistungen"
      subline="Steuerliche Kompetenz auf höchstem Niveau"
      bg={BackgroundImageTwo}
    />
    <ServiceBlog />
    <Footer />
    <SubFooter />
  </Layout>
)

export default LeistungenPage
